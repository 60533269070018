import { apiOrderPay, apiOrderClose, apiOrderTakedelivery } from "@/api/order/order"
export default {
    methods: {
        /**
         * 订单支付
         * @param {Object} out_trade_no
         */
        orderPay(orderData) {
            if (orderData.adjust_money == 0) {
                apiOrderPay({
                    order_ids: orderData.order_id
                }).then(res => {
                    if (res.code >= 0) {
                        this.$router.push({
                            path: "/pay",
                            query: {
                                code: res.data
                            }
                        })
                    } else {
                        this.$message({
                            message: res.message,
                            type: "warning"
                        })
                    }
                })
            } else {
                this.$confirm(this.$lang('orderMethod.payment_adjusted', this.$route) + orderData.pay_money + this.$lang('orderMethod.continue_pay', this.$route) + ', ' + this.$lang('orderMethod.prompt', this.$route) , {
                    confirmButtonText: this.$lang('orderMethod.confirm', this.$route) ,
                    cancelButtonText: this.$lang('orderMethod.cancel', this.$route) ,
                    type: "warning"
                }).then(() => {
                    apiOrderPay({
                        order_ids: orderData.order_id
                    }).then(res => {
                        if (res.code >= 0) {
                            this.$router.push({
                                path: "/pay",
                                query: {
                                    code: res.data
                                }
                            })
                        } else {
                            this.$message({
                                message: res.message,
                                type: "warning"
                            })
                        }
                    })
                })
            }
        },
        /**
         * 关闭订单
         * @param {Object} order_id
         */
        orderClose(order_id, callback) {
            this.$confirm(this.$lang('orderMethod.close_order', this.$route) + ', ' + this.$lang('orderMethod.prompt', this.$route) , {
                confirmButtonText: this.$lang('orderMethod.confirm', this.$route) ,
                cancelButtonText: this.$lang('orderMethod.cancel', this.$route) ,
                type: "warning"
            }).then(() => {
                apiOrderClose({
                    order_id
                }).then(res => {
                    this.$message({
                        message: this.$lang('orderMethod.close_order_success', this.$route) ,
                        type: "success"
                    })
                    typeof callback == "function" && callback()
                })
            })
        },
        /**
         * 订单收货
         * @param {Object} order_id
         */
        orderDelivery(order_id, callback) {
            this.$confirm(this.$lang('orderMethod.order_delivery_ok', this.$route) + ', ' + this.$lang('orderMethod.prompt', this.$route) , {
                confirmButtonText: this.$lang('orderMethod.confirm', this.$route) ,
                cancelButtonText: this.$lang('orderMethod.cancel', this.$route) ,
                type: "warning"
            }).then(() => {
                apiOrderTakedelivery({
                    order_id
                }).then(res => {
                    this.$message({
                        message: this.$lang('orderMethod.order_delivery_done', this.$route) ,
                        type: "success"
                    })
                    typeof callback == "function" && callback()
                })
            })
        }
    }
}
